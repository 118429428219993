.Agentmarketplace_main {
  margin-top: 56px;
  padding: 36px 160px;
}

.Agentmarketplace_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
}

.Agentmarketplace_inner h3 {
  color: var(--Neutrals-4, #777e90);

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}

.Agentmarketplace_inner h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
}

.Agentmarketplace_inner p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.Agentmarketplace_square {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.square_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.boxone {
  padding: 42.045px 42.519px;
  border-radius: 6px;
  background: var(--Primary-50, #f9f5ff);
}
.boxtwo {
  padding: 46.833px 47.682px;
  border-radius: 6px;
  background: var(--Primary-100, #fee);
}
.boxthree {
  padding: 31.089px 19.692px;
  border-radius: 6px;
  background: var(--Success-50, #ecfdf3);
}
.boxfour {
  padding: 31.255px 35.854px 30.924px 35.854px;
  background: #fff8e3;
}

.Agentmarketplace_square_bottom {
  margin-bottom: 200px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}
.boxfive {
  background: var(--Blue-light-100, #e0f2fe);
  padding: 32.2px 39.711px;
}

.boxsix {
  background: var(--Purple-100, #ebe9fe);
  padding: 31.089px 41.023px;
}

.boxsaven {
  padding: 31.089px 30.566px;
  background: var(--Orange-100, #ffead5);
}

.boxeight {
  padding: 38.184px 47.341px 38.995px 47.341px;
  background: var(--Ros-100, #ffe4e8);
}

.usuccess {
  display: flex;
  padding: 136px 343px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: var(--Gray-50, #f9fafb);
}
.success_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.success_inner h3 {
  color: var(--Neutrals-4, #777e90);
  text-align: center;

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}

.success_inner h2 {
  color: var(--Neutrals-2, #23262f);
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.24px;
}

.success_inner button {
  display: flex;
  height: 48px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: var(--Primary-100, #fee);
  color: var(--Primary-500, #ff5454);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  border: none;
  transition: background-color 0.5s ease-in-out;
}

.success_inner button:hover {
  background: var(--Primary-200, #fcc);
}

.Agentmarketplace_service {
  display: flex;
  padding: 58px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.Agentmarketplace_service_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.Agentmarketplace_service_inner h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}

.Agentmarketplace_service_inner h2 {
  color: var(--Gray-600, #475467);
  text-align: center;
  font-family: "DM Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
}

.Agentmarketplace_service_inner p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Body 2 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.big_four_square {
  margin: 49px 121px;
}

.big_four_square_inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.big_four_box {
  display: inline-flex;
  margin-right: 21px;
  padding: 91.143px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--Gray-50, #f9fafb);
}

.big_four_square_inner img {
  padding: 24px;
  border-radius: 19.357px;
}

.imgone {
  background: #e2e4f5;
}

.imgtwo {
  background: rgba(254, 237, 191, 0.69);
}

.imgthree {
  background: var(--Success-100, #d1fadf);
}

.imgfour {
  background: var(--Error-100, #fee4e2);
}

.agentmarket_solution {
  display: flex;
  flex-direction: column;
  padding: 82px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.agentmarket_solution h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}

.agentmarket_solution h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: "DM Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
}

.agentmarket_solution p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Body 2 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.six_box_sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 41px 24px;
}
.six_box_sec_top {
  display: flex;
  gap: 24px;
}

.sixboxtop {
  position: relative;
  overflow: hidden;
  width: 384px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 40px;
  height: 322px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 2px solid var(--Gray-200, #e4e7ec);
  background: rgba(252, 252, 253, 0.5);
}

.simage {
  margin-bottom: 16px;
  padding: 10px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;

  display: inline-flex;
gap: 5px;
}
.simageone {
  background: var(--Blue-light-100, #e0f2fe);
}
.simagetwo {
  background: var(--Pink-100, #fce7f6);
}
.simagethree {
  background: var(--Orange-200, #fddcab);
}

.sixboxtop h3 {
  color: var(--Gray-800, #1d2939);
  margin-bottom: 16px;
  padding-right: 109px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  letter-spacing: -0.32px;
}

.sixboxtop p {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -1.12px;
}

.sbimage {
  position: absolute;
  bottom: 0;
  right: 0;
}

.six_box_sec_bottom {
  display: flex;
  gap: 24px;
}

.six_box_sec_bottom {
  margin-bottom: 56px;
}

.simagefour {
  background: rgba(208, 235, 173, 0.66);
}

.simagefive {
  background: linear-gradient(
    90deg,
    rgba(127, 86, 217, 0.34) 0%,
    rgba(158, 119, 237, 0.34) 100%
  );
}

.simagesix {
  background: var(--Error-100, #fee4e2);
}
.agentmarketplace_mobile_main {
  display: inline-flex;
  margin: 32px 19px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.agentmarketplace_mobile_hero_main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.agentmarketplace_mobile_hero_solution {
  display: flex;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.agentmarketplace_mobile_hero_solution_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}
.agentmarketplace_mobile_hero_solution_inner h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.agentmarketplace_mobile_hero_solution_inner_text_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.agentmarketplace_mobile_hero_solution_inner_text_block h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.agentmarketplace_mobile_hero_solution_inner_text_block p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}
.agentmarketplace_mobile_hero_icons {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1.753px;
  flex-wrap: wrap;
}
.mobileimgone {
  display: flex;
  width: 86.685px;
  height: 86.685px;
  padding: 20.805px 21.04px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2.969px;
  background: var(--Primary-50, #f9f5ff);
}
.mobileimgtwo {
  display: flex;
  width: 86.685px;
  height: 86.685px;
  padding: 23.174px 23.595px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2.969px;
  background: var(--Primary-100, #fee);
}
.mobileimgthree {
  display: flex;
  width: 86.685px;
  height: 86.685px;
  padding: 15.384px 9.744px 15.384px 9.745px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2.969px;
  background: var(--Success-50, #ecfdf3);
}
.mobileimgfour {
  display: flex;
  width: 86.685px;
  height: 86.685px;
  padding: 15.466px 17.742px 15.302px 17.742px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #fff8e3;
}
.mobileimgfive {
  display: flex;
  width: 86.685px;
  height: 86.685px;
  padding: 15.934px 19.65px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--Blue-light-100, #e0f2fe);
}
.mobileimgsix {
  display: flex;
  width: 86.685px;
  height: 86.685px;
  padding: 15.384px 20.3px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--Purple-100, #ebe9fe);
}
.mobileimgsaven {
  display: flex;
  width: 86.685px;
  height: 86.685px;
  padding: 15.384px 15.125px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--Orange-100, #ffead5);
}
.mobileimgeight {
  display: flex;
  width: 86.685px;
  height: 86.685px;
  padding: 18.895px 23.426px 19.296px 23.426px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--Ros-100, #ffe4e8);
}
.agentmarketplace_mobile_cta {
  display: flex;
  margin-bottom: 32px;
  padding: 124px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--Gray-50, #f9fafb);
}
.agentmarketplace_mobile_cta h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.agentmarketplace_mobile_cta h2 {
  color: var(--Neutrals-2, #23262f);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.14px;
}
.agentmarketplace_mobile_cta button {
  display: flex;
  padding: 10.123px 15.184px;
  justify-content: center;
  align-items: center;
  gap: 7.592px;
  border-radius: 3.796px;
  background: var(--Primary-100, #fee);
  color: var(--Primary-500, #ff5454);
  text-align: center;
  font-family: Poppins;
  font-size: 9.846px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.184px; /* 154.215% */
  transition: background-color 0.5s ease-in-out;
}

.agentmarketplace_mobile_cta button:hover {
  background: var(--Primary-200, #fcc);
}
.agentmarketplace_mobile_services_main {
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.agentmarketplace_mobile_services_solution {
  display: flex;
  padding: 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.agentmarketplace_mobile_services_solution h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.agentmarketplace_mobile_services_solution_text_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.agentmarketplace_mobile_services_solution_text_box h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.agentmarketplace_mobile_services_solution_text_box p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}
.agentmarketplace_mobile_services_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
}
.agentmarketplace_mobile_services_image_div {
  display: flex;
  padding: 41.72px;
  justify-content: center;
  align-items: center;
  border-radius: 7.324px;
  background: var(--Gray-50, #f9fafb);
}
.agentmarketplace_mobile_services_imagesone {
  display: flex;
  padding: 10.986px;
  justify-content: center;
  align-items: center;
  gap: 7.384px;
  border-radius: 8.861px;
  background: #e2e4f5;
}
.agentmarketplace_mobile_services_imagestwo {
  display: flex;
  padding: 10.986px;
  justify-content: center;
  align-items: center;
  gap: 7.384px;
  border-radius: 8.861px;
  background: rgba(254, 237, 191, 0.69);
}
.agentmarketplace_mobile_services_imagesthree {
  display: flex;
  padding: 10.986px;
  justify-content: center;
  align-items: center;
  gap: 7.384px;
  border-radius: 8.861px;
  background: var(--Success-100, #d1fadf);
}
.agentmarketplace_mobile_services_imagesfour {
  display: flex;
  padding: 10.986px;
  justify-content: center;
  align-items: center;
  gap: 7.384px;
  border-radius: 8.861px;
  background: var(--Error-100, #fee4e2);
}
.agentmarketplace_mobile_sixbox_services_main {
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.agentmarketplace_mobile_sixbox_services_solution {
  display: flex;
  padding: 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.agentmarketplace_mobile_sixbox_services_solution h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.agentmarketplace_mobile_sixbox_services_solution_text_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.agentmarketplace_mobile_sixbox_services_solution_text_box h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.agentmarketplace_mobile_sixbox_services_solution_text_box p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}
.agentmarketplace_mobile_sixbox_services_icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.agentmarketplace_mobile_sixbox_services_icons_main_divs {
  position: relative;
  padding-right: 30px;
  margin: 0px 20px;
  overflow: hidden;
  height: 294px;
  border-radius: 14px;
  padding-left: 30px;
  padding-top: 36px;
  border: 1.833px solid var(--Gray-200, #e4e7ec);
  background: rgba(252, 252, 253, 0.5);
}
.agentmarketplace_mobile_sixbox_services_icons_main_divs_imgone {
  display: inline-flex;
  margin-bottom: 13px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: var(--Blue-light-100, #e0f2fe);
}
.agentmarketplace_mobile_sixbox_services_icons_main_divs h2 {
  color: var(--Gray-800, #1d2939);
  margin-bottom: 13px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
}
.agentmarketplace_mobile_sixbox_services_icons_main_divs p {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}
.agentmarketplace_mobile_sixbox_services_icons_main_divs_imgoneb {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.agentmarketplace_mobile_sixbox_services_icons_main_divs_imgtwo {
  display: inline-flex;
  margin-bottom: 13px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 6px;
  background: var(--Pink-100, #fce7f6);
}
.agentmarketplace_mobile_sixbox_services_icons_main_divs_imgthree {
  display: inline-flex;
  margin-bottom: 13px;
  padding: 9.167px;
  justify-content: center;
  align-items: center;
  gap: 4.583px;
  border-radius: 6px;
  background: var(--Orange-200, #fddcab);
}
.agentmarketplace_mobile_sixbox_services_icons_main_divs_imgtfour {
  display: inline-flex;
  margin-bottom: 13px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: rgba(208, 235, 173, 0.66);
}
.agentmarketplace_mobile_sixbox_services_icons_main_divs_imgtfive {
  display: inline-flex;
  margin-bottom: 13px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: linear-gradient(
    90deg,
    rgba(127, 86, 217, 0.34) 0%,
    rgba(158, 119, 237, 0.34) 100%
  );
}
.agentmarketplace_mobile_sixbox_services_icons_main_divs_imgtsix{
  display: inline-flex;
  margin-bottom: 13px;
padding: 9.167px;
justify-content: center;
align-items: center;
gap: 4.583px;
border-radius: 5.5px;
background: var(--Error-100, #FEE4E2);
}
