.ptw_top_section {
  display: flex;
  padding: 82px 0px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: #f0f2f5;
  margin-bottom: 56px;
}
.ptw_top_section h2 {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 100% */
  letter-spacing: -0.8px;
}
.ptw_top_section p {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.ptw_content_outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  margin: 0px 324px 110px 324px;
}
.ptw_content_inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
}
.ptw_content_inner h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.ptw_content_texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.ptw_content_texts p {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
@media screen and (max-width: 768px) {
  .ptw_top_section {
    display: flex;
    width: 393px;
    padding: 32px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
  .ptw_top_section h2 {
    color: var(--Gray-800, #1d2939);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
  }
  .ptw_top_section p {
    color: var(--Gray-500, #667085);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }
  .ptw_content_outer {
    display: flex;
    margin: 0px;
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    justify-content: flex-end;
    /* background: var(--Gray-50, #f9fafb); */
    align-items: center;
    gap: 32px;
  }
  .ptw_content_inner {
    display: flex;
    padding: 0px 0px 0px 0px;
    width: 352px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .ptw_content_inner h2 {
    color: var(--Gray-800, #1d2939);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
  }
  .ptw_content_texts{
    padding: 0px 10px;
  }
  .ptw_content_texts p {
    color: var(--Gray-500, #667085);
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 180% */
  }
}
