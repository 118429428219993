.footor_main {
  display: flex;
  padding: 50px 125px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(245, 245, 245, 0.66);
}
.footor_top {
  display: flex;
  margin-bottom: 48px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.footor_top_logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.footor_top_logo img {
  width: 190.393px;
  margin-top: -8px;
  height: 38.436px;
  flex-shrink: 0;
}
.footor_top_logo p {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.footor_top_links {
  display: flex;
  width: 160px;
  flex-direction: column;
  align-items: flex-start;
}

.footor_top_links h2 {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin-bottom: 40px;
  align-self: stretch;
}

.footor_top_links h3 {
  color: var(--Gray-400, #98a2b3);
  margin-bottom: 24px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}

.footor_top_social {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
}

.footor_top_social h2 {
  align-self: stretch;
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.footor_top_social_icons {
  display: flex;
}

.footor_top_social_icons img {
  width: 20px;
  height: 20px;
  margin-right: 28px;
}

.footor_top_social_icons_bottom img {
  margin-right: 28px;
  color: var(--Gray-500, #667085);
  font-family: "Font Awesome 6 Brands";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
}

.footor_bottom {
  display: flex;
  padding: 28px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.horizontal-line {
  width: 90%;
  height: 1px;
  border: none;
  background: var(--Neutrals-6, #e6e8ec);
  margin-top: 28px;
  margin-bottom: 32px;
}
.footor_bottom_inner {
  display: flex;
  height: 35px;
  padding: 11px 0px;
  justify-content: space-between;
  align-items: center;
}
.footor_bottom_inner_text p {
  color: var(--Gray-400, #98a2b3);
  margin-right: 28px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.footor_bottom_inner_links {
  display: flex;
}

.footor_bottom_inner_links h3 {
  margin-right: 28px;
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

/* mobile footer */

.mobile_footer {
  display: flex;
  padding: 64px 22px 24px 22px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  background: #f5f5f5;
}
.mobile_footer_inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
}
.mobile_footer_inner_top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.mobile_footer_inner_top_logo {
  display: flex;
  height: 101px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.mobile_footer_inner_top_logo img {
  width: 190.393px;
  height: 38.436px;
}
.mobile_footer_inner_top_logo p {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.footer_devider {
  height: 1px;
  align-self: stretch;
  background: var(--Gray-200, #e4e7ec);
}
.footer_menu_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer_menu_item h3 {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 183.333% */
  text-transform: capitalize;
}
.footer_menu_item img {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}
.footer_social_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.footer_social_text h2 {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  text-transform: capitalize;
}
.footer_social_icons{
  display: flex;
justify-content: center;
align-items: center;
gap: 28px;
align-self: stretch;
}

.mobile_footer_inner_bottom{
  display: flex;
padding-bottom: 24px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 24px;
}
.mobile_footer_inner_bottom h3{
  color: var(--Gray-400, #98A2B3);

/* Caption 2 */
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
}
.pnt{
  display: flex;
align-items: center;
gap: 24px;
align-self: stretch;
}
.pnt h2{
  color: var(--Gray-400, #98A2B3);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 166.667% */
}


.footer_dropdown {
  overflow: hidden;
  max-height: 0;
  gap: 12px;
  display: flex;
  flex-direction: column;
  transition: max-height 0.5s ease-in-out;
}

.footer_dropdown_open {
  max-height: 500px; /* adjust as needed */
}

.footer_dropdown h3 {
  color: var(--Gray-400, #98A2B3);
font-family: "DM Sans";
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 133.333% */
}

.footer_menu_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}


.footer_social_icons img {
  cursor: pointer;
}

/* Footor.css */

/* More specific selector for the Link component */
.mobile_footer a {
  text-decoration: none; /* This removes the underline */
  color: inherit; /* This ensures that the text color is inherited from the parent element */
}

.mobile_footer a:hover {
  color: #000; /* Change this to your desired hover color */
}

/* Add any additional styles you need here */


.footor_main a{
  text-decoration: none;
  color: inherit;
}
