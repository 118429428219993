.channel_main {
  margin: 56px 0px;
  padding: 74px 0px;
}
.channel_inner {
  display: flex;
  padding: 0px 0.812px 0.555px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.channel_inner_content {
  display: flex;
  width: 916px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.channel_inner_content h3 {
  color: var(--Neutrals-4, #777e90);
  text-align: center;

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}

.channel_inner_content h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
}

.channel_inner_content p {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.channel_works {
  margin-bottom: 56px;
  margin-top: 22px;
  display: flex;
  background: var(--Gray-50, #f9fafb);
  padding: 82px 0px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.channel_works h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.channel_works h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: "DM Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
}

.channel_works p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Body 2 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.channel_image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 78px;
}

.channel_elevate {
  display: flex;
  margin: 56px 0px;
  flex-direction: column;
  padding: 46px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.channel_elevate h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}

.channel_elevate h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: "DM Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
}

.channel_elevate p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Body 2 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.channel_mainsquare {
  margin-bottom: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 56px 24px;
  flex-wrap: wrap;
}
.channel_square_div {
  display: flex;
  width: 588px;
  height: 732px;
  padding: 72px 104px;
  align-items: center;
  gap: 122px;
  flex-shrink: 0;
}

.csone {
  border-radius: 10px;
  background: #fff7f7;
}
.cstwo {
  border-radius: 10px;
  background: var(--Warning-50, #fffaeb);
}

.csthree {
  border-radius: 10px;
  background: #f4fef8;
}

.csfour {
  border-radius: 10px;
  background: var(--Blue-gray-50, #f8f9fc);
}

.channel_square_inner {
  display: flex;
  width: 445px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
}

.channel_inner_imgtext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
}

.channel_inner_img {
  flex-shrink: 0;
  padding: 12px;
  border-radius: 14px;
}
.channel_inner_imgone {
  background: #fee;
}
.channel_inner_imgtwo {
  background: var(--Warning-100, #fef0c7);
}
.channel_inner_imgthree {
  background: var(--Success-100, #d1fadf);
}
.channel_inner_imgfour {
  background: var(--Blue-gray-200, #c8cce5);
}

.channel_inner_imgtext img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.channel_inner_imgtext h3 {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
}

.channel_inner_textone {
  color: #ffb3b3;
}
.channel_inner_texttwo {
  color: var(--Warning-300, #fec84b);
}
.channel_inner_textthree {
  color: var(--Success-200, #a6f4c5);
}
.channel_inner_textfour {
  color: var(--Blue-gray-300, #9ea5d1);
}

.channel_square_inner h2 {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px; /* 115% */
  letter-spacing: -0.8px;
}

.channel_square_inner p {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  opacity: 0.33;
}

.channel_contact_section {
  display: flex;
  background: #fcfcfd;
  padding: 134px 160px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 78px;
}
.channel_contact_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.channel_contact_inner_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.channel_contact_inner_top h2 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Hairline 1 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
  text-transform: uppercase;
}

.channel_contact_inner_top h3 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.36px;
}

.channel_contact_inner_bottom {
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.channel_contact_inner_bottom p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.channel_contact_inner_bottom button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: none;
  border-radius: 6px;
  background: var(--Primary-500, #ff5454);
  color: var(--Neutrals-8, #fcfcfd);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  transition: background-color 0.5s ease-in-out;
}

.channel_contact_inner_bottom button:hover {
  background-color: #980000;
}

.channel_manager_mobile_hero {
  margin: 32px 0px;
  gap: 24px;
}
.channel_manager_mobile_hero_solutions {
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}
.channel_manager_mobile_hero_solutions h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.channel_manager_mobile_hero_solutions_text_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.channel_manager_mobile_hero_solutions_text_box h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.channel_manager_mobile_hero_solutions_text_box p {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.24px;
}

.channel_manager_mobile_hiw {
  margin: 32px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.channel_manager_mobile_hiw_services {
  display: flex;
  padding: 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.channel_manager_mobile_hiw_services_inner {
  display: flex;
  padding: 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.channel_manager_mobile_hiw_services_inner h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.channel_manager_mobile_hiw_services_inner_text {
  display: flex;
  width: 236px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.channel_manager_mobile_hiw_services_inner_text h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}

.channel_manager_mobile_hiw_services_inner_text p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.channel_manager_mobile_hiw_flowchart img {
  width: 351px;
  height: 94px;
}
.channel_manager_mobile_services {
  display: flex;
  padding: 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.channel_manager_mobile_services_solutions {
  display: flex;
  padding: 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.channel_manager_mobile_services_solutions h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.channel_manager_mobile_services_solutions_text_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.channel_manager_mobile_services_solutions_text_box h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.channel_manager_mobile_services_solutions_text_box p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.channel_manager_mobile_services_channel_manager {
  display: flex;
  width: 352px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.channel_manager_mobile_services_channel_manager_main_div {
  display: flex;
  width: 352px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.channel_manager_mobile_services_channel_manager_main_div_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  flex-shrink: 0;
}
.channel_manager_mobile_services_channel_manager_main_div_left_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 19.148px;
}
.channel_manager_mobile_services_channel_manager_main_div_left_top img {
  width: 42px;
  padding: 7px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 8.167px;
}
.channel_manager_mobile_services_channel_manager_main_div_left_top h3 {
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 150% */
  letter-spacing: 0.56px;
  text-transform: uppercase;
}
.channel_manager_mobile_services_channel_manager_main_div_left h2 {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 130% */
  letter-spacing: -0.4px;
}
.channel_manager_mobile_services_channel_manager_main_div_left p {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
  opacity: 0.33;
}
.cmmscmmdone {
  border-radius: 6px;
  background: #fff7f7;
}
.cmmscmmdone h3 {
  color: #ffb3b3;
}
.cmmscmmdone img {
  background: #fee;
}
.cmmscmmdtwo {
  border-radius: 6px;
  background: var(--Warning-50, #fffaeb);
}
.cmmscmmdtwo h3 {
  color: var(--Warning-300, #fec84b);
}
.cmmscmmdtwo img {
  background: var(--Warning-100, #fef0c7);
}
.cmmscmmdthree {
  border-radius: 5.967px;
  background: #f4fef8;
}
.cmmscmmdthree h3 {
  color: var(--Success-200, #a6f4c5);
}
.cmmscmmdthree img {
  background: var(--Success-100, #d1fadf);
}
.cmmscmmdfour {
  border-radius: 5.993px;
  background: var(--Blue-gray-50, #f8f9fc);
}
.cmmscmmdfour h3 {
  color: var(--Blue-gray-300, #9ea5d1);
}
.cmmscmmdfour img {
  background: var(--Blue-gray-200, #c8cce5);
}
.cmm_mobile_main {
  display: flex;
  width: 352px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.cmm_mobile_main_four_div {
  display: flex;
  width: 352px;
  height: 438px;
  padding: 42px 62px;
  align-items: center;
}
.cmm_one {
  border-radius: 6px;
  background: #fff7f7;
}
.cmm_two {
  border-radius: 6px;
  background: var(--Warning-50, #fffaeb);
}
.cmm_three {
  border-radius: 5.967px;
  background: #f4fef8;
}
.cmm_four {
  border-radius: 5.993px;
  background: var(--Blue-gray-50, #f8f9fc);
}
.cmm_mobile_main_four_div_left_sec {
  display: flex;
  width: 239px;
  height: 307px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  flex-shrink: 0;
}
.cmm_mobile_main_four_div_left_sec_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 19.148px;
}
.cmmffdlst_one {
  border-radius: 8.167px;
  background: #fee;
  padding: 7px;
}
.cmmffdlst_one_text{
  color: #ffb3b3;
}
.cmmffdlst_two{
  border-radius: 8.167px;
background: var(--Warning-100, #FEF0C7);
padding: 7px;
}
.cmmffdlst_two_text{
  color: var(--Warning-300, #FEC84B);
}
.cmmffdlst_three{
  border-radius: 8.354px;
background: var(--Success-100, #D1FADF);
padding: 7px;
}
.cmmffdlst_four{
  border-radius: 8.391px;
background: var(--Blue-gray-200, #C8CCE5);
padding: 7px;
}
.cmmffdlst_four_text{
  color: var(--Blue-gray-300, #9EA5D1);
}
.cmmffdlst_three_text{
  color: var(--Success-200, #A6F4C5);
}
.cmm_mobile_main_four_div_left_sec_top h3 {
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 150% */
  letter-spacing: 0.56px;
  text-transform: uppercase;
}
.cmm_mobile_main_four_div_left_sec h2{
  color: var(--Gray-600, #475467);
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 26px; /* 130% */
letter-spacing: -0.4px;
}

.cmm_mobile_main_four_div_left_sec p{
  color: var(--Gray-700, #344054);
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 160% */
opacity: 0.33;
}