.main_section {
  /* display: flex; */
  padding: 36px 0px;
  margin-top: 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.top_heading h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.bottom_heading h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
}

.bottom_paragraph p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: -0.16px;
}
.main_section_img {
  display: flex;
  padding: 11.949px 25.561px 0px 7.761px;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
}

.about_us {
  margin-top: 56px;
  padding: 124px 120px 124px 120px;
  gap: 10px;
  background: var(--Gray-25, #fcfcfd);
}

.about_us_inner {
  padding: 60px;
  gap: 10px;
  border-radius: 0px 24px 24px 0px;
  border: 1px solid var(--Secondary-3, #e8faf4);
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(119, 126, 144, 0.04);
}

.about_us_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about_us_content h3 {
  margin-bottom: 18px;
  align-self: stretch;
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}

.about_us_content h2 {
  margin-bottom: 24px;
  color: var(--Neutrals-2, #23262f);
  text-align: center;
  font-family: Outfit;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 125% */
  align-self: stretch;
}
.about_us_content p {
  margin-bottom: 18px;
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.16px;
  align-self: stretch;
}
.my_button {
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 12px;
  border-radius: 6px;
  background: var(--Primary-100, #fee);
  color: var(--Primary-500, #ff5454);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  transition: background-color 0.5s ease-in-out;
}

.my_button:hover {
  background-color: #ffcccc;
}

.mid_sec_outer {
  margin-top: 56px;
  padding: 124px 120px;
}
.mid_sec_inner {
  margin-bottom: 56px;
  display: flex !important;
  justify-content: space-between;
  margin-bottom: 56px;
}

.mid_sec_inner_left {
  padding: 51px 60px;
  align-items: center;
  gap: 10px;
}
.mid_sec_inner_left h2 {
  margin-bottom: 24px;
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  text-align: left;
  color: #475467;
}
.mid_sec_inner_left p {
  margin-bottom: 32px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #98a2b3;
}
.mid_sec_button {
  padding: 12.38px 18.57px 12.38px 18.57px;
  font-size: 12.383px;
  gap: 9.29px;
  border-radius: 4.64px;
  color: white;
  font-family: Poppins;
  cursor: pointer;
  border: none;
  background-color: #ff5454;
  transition: background-color 0.5s ease-in-out;
}
.mid_sec_button:hover {
  background-color: #d54343;
}

.mid_sec_inner_right {
  display: flex;
  width: 308px;
  height: 308px;
  padding: 23.753px 30.148px 23.87px 31.692px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.contect_us_main {
  background: #fcfcfd;
  margin-bottom: 56px;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 3163px;
  padding: 134px 160px 134px 160px;
  gap: 78px;
}

.contect_us_inner h3 {
  margin-bottom: 16px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: #98a2b3;
  text-transform: uppercase;
}

.contect_us_inner h2 {
  margin-bottom: 32px;
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #1d2939;
}

.contect_us_inner p {
  margin-bottom: 32px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #98a2b3;
}

.contect_us_button {
  padding: 16px 24px 16px 24px;
  gap: 12px;
  border-radius: 6px;
  border: none !important;
  background-color: #ff5454;
  color: white !important;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  transition: background-color 0.5s ease-in-out;
}
.contect_us_button:hover {
  background-color: #d54343;
}

.mobile_hero {
  display: flex;
  margin: 32px 20.5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.mobile_hero_top {
  display: flex;
  padding: 14px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.mobile_hero_top_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}
.mobile_hero_top_main h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.mobile_hero_top_bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.mobile_hero_top_bottom h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.mobile_hero_top_bottom p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.12px;
}
.mobile_hero_bottom {
  display: flex;
  padding: 4.624px 9.921px 0px 3.013px;
  align-items: center;
  align-self: stretch;
}
.mobile_hero_bottom img {
  width: 339.066px;
  height: 207.568px;
}
.mobile_aboutus_main {
  display: flex;
  margin-bottom: 32px;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--Gray-25, #fcfcfd);
}
.mobile_aboutus_inner {
  display: flex;
  padding: 60px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0px 24px 24px 0px;
  border: 1px solid var(--Secondary-3, #e8faf4);
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(119, 126, 144, 0.04);
}
.mobile_aboutus_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.mobile_aboutus_content h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.mobile_aboutus_inner_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.mobile_aboutus_inner_content h2 {
  color: var(--Neutrals-2, #23262f);
  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
}
.mobile_aboutus_inner_content p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.12px;
}
.mobile_aboutus_content button {
  display: flex;
  padding: 10.123px 15.184px;
  justify-content: center;
  align-items: center;
  gap: 7.592px;
  border-radius: 3.796px;
  background: var(--Primary-100, #fee);
  color: var(--Primary-500, #ff5454);
  text-align: center;
  font-family: Poppins;
  font-size: 9.846px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.184px; /* 154.215% */
  transition: background-color 0.5s ease-in-out;
}

.mobile_aboutus_content button:hover {
  background-color: #ffcccc;
}
.mobile_home_fourbox_main {
  margin: 0px 20.5px 32px 20.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.mobile_home_fourbox_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.mobile_home_fourbox_top {
  display: flex;
  padding: 12px 38px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 24px 0px 0px 24px;
  box-shadow: 0px 0px 40px 0px rgba(119, 126, 144, 0.1);
}
.mobile_home_fourbox_top_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}
.mobile_home_fourbox_top_inner_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.mobile_home_fourbox_top_inner_content h2 {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}
.mobile_home_fourbox_top_inner_content p {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.12px;
}
.mobile_home_fourbox_top_inner_button button {
  display: flex;
  height: 36px;
  padding: 12.098px 18.147px;
  justify-content: center;
  align-items: center;
  gap: 9.074px;
  border-radius: 4.8px;
  background: var(--Primary-500, #ff5454);
  color: var(--Neutrals-8, #fcfcfd);
  text-align: center;
  font-family: Poppins;
  font-size: 9.6px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.2px; /* 200% */
  transition: background-color 0.5s ease-in-out;
}
.mobile_home_fourbox_top_inner_button button:hover {
  background-color: #d54343;
}
.mobile_home_fourbox_bottom img {
  width: 214px;
  height: 214px;
}

.mobile_contactus_main {
  margin: 0px 20.5px 32px 20.5px;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.mobile_contactus_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  flex-shrink: 0;
}
.mobile_contactus_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.mobile_contactus_content h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.mobile_contactus_content h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.2px;
}
.mobile_contactus_inner p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}
.mobile_contactus_inner button {
  display: flex;
  height: 30px;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 8px !important;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Primary-500, #ff5454);
  color: var(--Neutrals-8, #fcfcfd);
  text-align: center;
  font-family: Poppins;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 200% */
  transition: background-color 0.5s ease-in-out;
}
.mobile_contactus_inner button:hover {
  background-color: #d54343;
}

.mobile_div a {
  text-decoration: none; /* This removes the underline */
  color: inherit; /* This ensures that the text color is inherited from the parent element */
}

.mobile_div a:hover {
  color: #000; /* Change this to your desired hover color */
}
.desktop_div a {
  text-decoration: none; /* This removes the underline */
  color: inherit; /* This ensures that the text color is inherited from the parent element */
}

.desktop_div a:hover {
  color: #000; /* Change this to your desired hover color */
}


