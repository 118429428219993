.mobile-navbar {
  display: none !important;
}
.mobile_div{
  display: none !important;
}

.mobile_footer{
  display: none !important;
}
@media (max-width: 768px) {
  .desktop-navbar {
    display: none !important;
  }
  .mobile_div{
    display: flex !important;
    flex-direction: column !important;;
  }
  .desktop_div{
    display: none !important;
  
  }
  .footor_main{
    display: none !important;
  }
  .mobile-navbar {
    display: flex !important;
  }
  .mobile_footer{
    display: flex !important;
  }
}


.mainh3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}

.mainh2 {
  color: #344054;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px; /* 140% */
  letter-spacing: -0.8px;
}

.mainp {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}
