.aboutpage_main {
  display: flex;
  margin: 56px 0px;
  padding: 36px 0px 51.819px 0px;
  flex-direction: column;
  align-items: center;
  gap: 75px;
}
.aboutpage_main_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.aboutpage_main_top h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.aboutpage_main_top h2 {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
}
.aboutpage_main_top p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}
.aboutpage_main_bottom {
  display: flex;
  padding: 82.477px 37.028px 81.555px 36.892px;
  justify-content: center;
  align-items: center;
}
.aboutpage_twosec {
  display: flex;
  margin: 0px 120px;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
}
.aboutpage_twosec_top {
  border-radius: 16px;
  background: var(--Gray-50, #f9fafb);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.aboutpage_twosec_top_left {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  gap: 24px;
}
.aboutpage_twosec_top h3 {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
  letter-spacing: -0.4px;
}
.aboutpage_twosec_top p {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}
.aboutpage_twosec_top_right {
  display: flex;
  margin-right: 141px;
  padding: 82.851px 27.933px 81.414px 28.275px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.aboutpage_twosec_bottom {
  margin-bottom: 56px;
  border-radius: 16px;
  background: var(--Gray-50, #f9fafb);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aboutpage_twosec_bottom_left {
  display: flex;
  margin-left: 112px;
  padding: 61.85px 26.434px 35.723px 27.283px;
  justify-content: center;
  align-items: center;
}
.aboutpage_twosec_bottom_right {
  display: flex;
  flex-direction: column;
  margin-right: 100px;
  gap: 24px;
}

.aboutpage_twosec_bottom_right h3 {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
  letter-spacing: -0.4px;
}
.aboutpage_twosec_bottom_right p {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}
.aboutpage_ease {
  display: flex;
  margin: 0px 120px 56px 120px;
  padding: 99px 254.305px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background: var(--Gray-50, #f9fafb);
}
.aboutpage_ease h3{
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}

.aboutpage_ease button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: var(--Primary-500, #ff5454);
  border: none;
  color: var(--Neutrals-8, #fcfcfd);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  transition: background-color 0.5s ease-in-out;
}

.aboutpage_ease button:hover {
  background-color: #d54343;
}
.aboutpage_mobile_main {
  margin: 32px 0px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.aboutpage_mobile_hero {
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
}

.aboutpage_mobile_hero_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}
.aboutpage_mobile_hero_inner_solution {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.aboutpage_mobile_hero_inner_solution h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}

.aboutpage_mobile_hero_inner_solution_text_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.aboutpage_mobile_hero_inner_solution_text_box h2 {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}

.aboutpage_mobile_hero_inner_solution_text_box p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}
.aboutpage_mobile_hero_inner_image img {
  width: 230px;
  height: 229.139px;
}
.aboutpage_mobile_features{
  display: flex;
padding: 8px 21px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
}
.aboutpage_mobile_features_inner{
  display: flex;
padding: 50px 22px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 32px;
align-self: stretch;
border-radius: 10px;
background: var(--Gray-50, #F9FAFB);
}
.aboutpage_mobile_features_inner_content{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 24px;
align-self: stretch;
}
.aboutpage_mobile_features_inner_content h2{
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
}
.aboutpage_mobile_features_inner_content p{
  color: var(--Gray-400, #98A2B3);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 166.667% */
letter-spacing: -0.24px;
}

.aboutpage_mobile_features_inner_image img{
  width: 230px;
height: 230px;
}























































