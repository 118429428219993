.PaymentSolutions_main {
  margin: 150px 135px 56px 123px;
}
.PaymentSolutions_payment {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.instarem_logo{
  width: 181px;
height: 73px;
}
.PaymentSolutions_payment_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}
.PaymentSolutions_payment_left h3 {
  color: var(--Gray-400, #98a2b3);

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.PaymentSolutions_payment_left h2 {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
}
.PaymentSolutions_payment_left p {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.PaymentSolutions_payment_right {
  display: flex;
  width: 380.396px;
  height: 380.396px;
  padding: 42.315px 26.723px 41.487px 28.53px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.PaymentSolutions_logos {
  display: flex;
  flex-direction: column;
  gap: 66px;
  padding: 0px 0px;
  margin-bottom: 185px;
  align-items: center;
  justify-content: center;
}
.PaymentSolutions_logos_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 66px 59px;
  flex-wrap: wrap;
}
.PaymentSolutions_fivebox_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
}
.PaymentSolutions_fivebox_main_top{
    display: flex;
padding: 0px 1px;
align-items: center;
gap: 23px;
}


.PaymentSolutions_fivebox_main_top_one {
  display: flex;
  width: 384px;
  padding: 107px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border-radius: 16px;
  border: 1px solid #d0d5dd;
  background: var(--Gray-50, #f9fafb);
}

.PaymentSolutions_fivebox_main_top_one img {
  display: flex;
  padding: 12.727px;
  justify-content: center;
  align-items: center;
  gap: 6.364px;
  border-radius: 7.636px;
  background: #f4ebff;
}
.PaymentSolutions_fivebox_main_top_one p{
    color: var(--Gray-800, #1D2939);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.28px;
}
.PaymentSolutions_fivebox_main_top_two{
    display: flex;
width: 384px;
padding: 106px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 16px;
border-radius: 16px;
border: 1px solid #D0D5DD;
background: var(--Gray-50, #F9FAFB);
}
.PaymentSolutions_fivebox_main_top_two img{
    display: flex;
padding: 12.727px;
justify-content: center;
align-items: center;
gap: 6.364px;
border-radius: 7.636px;
background: var(--Warning-100, #FEF0C7);

}
.PaymentSolutions_fivebox_main_top_two p{
    color: var(--Gray-800, #1D2939);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.28px;

}
.PaymentSolutions_fivebox_main_top_three{
    display: flex;
width: 384px;
padding: 106px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 16px;
border-radius: 16px;
border: 1px solid #D0D5DD;
background: var(--Gray-50, #F9FAFB);

}
.PaymentSolutions_fivebox_main_top_three img{
    display: flex;
padding: 12.727px;
justify-content: center;
align-items: center;
gap: 6.364px;
border-radius: 7.636px;
background: var(--Success-100, #D1FADF);

}
.PaymentSolutions_fivebox_main_top_three p{
    color: var(--Gray-800, #1D2939);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */   
}


.PaymentSolutions_fivebox_main_bottom {
    display: flex;
    padding: 0px 1px;
    align-items: center;
    gap: 23px;
}

.PaymentSolutions_fivebox_main_bottom_one{
    display: flex;
width: 384px;
padding: 107.106px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 13.789px;
flex-shrink: 0;
border-radius: 16px;
border: 1px solid #D0D5DD;
background: var(--Gray-50, #F9FAFB);
}

.PaymentSolutions_fivebox_main_bottom_one img{
    display: flex;
padding: 12.727px;
justify-content: center;
align-items: center;
gap: 6.364px;
border-radius: 7.636px;
background: rgba(255, 204, 204, 0.90);

}

.PaymentSolutions_fivebox_main_bottom_one p{
    color: var(--Gray-800, #1D2939);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.28px;

}

.PaymentSolutions_fivebox_main_bottom_two{
    display: flex;
width: 384px;
padding: 106px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 16px;
flex-shrink: 0;
border-radius: 16px;
border: 1px solid #D0D5DD;
background: var(--Gray-50, #F9FAFB);
}

.PaymentSolutions_fivebox_main_bottom_two img{
    display: flex;
padding: 12.727px;
justify-content: center;
align-items: center;
gap: 6.364px;
border-radius: 7.636px;
background: var(--Blue-light-100, #E0F2FE);

}

.PaymentSolutions_fivebox_main_bottom_two p{
    color: var(--Gray-800, #1D2939);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.28px;

}

.payment_solutions_mobile_main{
  margin: 32px 0px;
  display: inline-flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 32px;
flex-shrink: 0;
}
.payment_solutions_mobile_hero{
  display: flex;
padding: 12px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 32px;
}
.payment_solutions_mobile_hero_title{
  display: flex;
width: 393px;
padding: 0px 22px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 8px;
}
.payment_solutions_mobile_hero_title h3{
  color: var(--Gray-400, #98A2B3);
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 12px; /* 120% */
text-transform: uppercase;
}
.payment_solutions_mobile_hero_title_text_box{
  display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 8px;
align-self: stretch;
}

.payment_solutions_mobile_hero_title h2{
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.payment_solutions_mobile_hero_title p{
  color: var(--Gray-400, #98A2B3);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
letter-spacing: -0.24px;
}
.payment_solutions_mobile_hero_img img{
  width: 230px;
height: 230px;
}
.payment_solutions_mobile_logos{
  display: flex;
justify-content: center;
align-items: center;
align-content: center;
gap: 32px 24px;
flex-wrap: wrap;
}
.payment_solutions_mobile_logos img{
  width: 88.74px;
height: 35.79px;
flex-shrink: 0;
}
.payment_solutions_mobile_howitworks{
  display: flex;
flex-direction: column;
align-items: center;
gap: 32px;
flex: 1 0 0;
}
.payment_solutions_mobile_howitworks_services{
  display: flex;
width: 393px;
padding: 32px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 24px;
background: var(--Gray-50, #F9FAFB);
}
.payment_solutions_mobile_howitworks_services_solutions{
  display: flex;
padding: 0px 40px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
}
.payment_solutions_mobile_howitworks_services_solutions h3{
  color: var(--Gray-400, #98A2B3);
text-align: center;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 12px; /* 120% */
text-transform: uppercase;
}
.payment_solutions_mobile_howitworks_services_solutions_text_box{
  display: flex;
width: 273px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
}
.payment_solutions_mobile_howitworks_services_solutions_text_box h2{
  color: var(--Gray-800, #1D2939);
text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 140% */
letter-spacing: -0.4px;
}
.payment_solutions_mobile_howitworks_services_solutions_text_box p{
  color: var(--Gray-400, #98A2B3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}
.payment_solutions_mobile_howitworks_features{
  display: flex;
padding: 0px 19px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 24px;
flex: 1 0 0;
align-self: stretch;
}
.payment_solutions_mobile_howitworks_features_inner_divs{
  display: flex;
padding: 113px 50px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 14px;
align-self: stretch;
border-radius: 16px;
border: 0.917px solid #D0D5DD;
background: var(--Gray-50, #F9FAFB);
}
.payment_solutions_mobile_howitworks_features_inner_divs h2{
  color: var(--Gray-800, #1D2939);
text-align: center;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 180% */
letter-spacing: -0.2px;
}
.psmhfidimgone{
  display: flex;
padding: 12px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 6px;
background: #F4EBFF;
}
.psmhfidimgtwo{
  display: flex;
padding: 12px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 6px;
background: var(--Warning-100, #FEF0C7);
}
.psmhfidimgthree{
  display: flex;
padding: 12px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 6px;
background: var(--Success-100, #D1FADF);
}
.psmhfidimgfour{
  display: flex;
padding: 12px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 6px;
background: rgba(255, 204, 204, 0.90);
}
.psmhfidimgfive{
  display: flex;
padding: 12px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 6px;
background: var(--Blue-light-100, #E0F2FE);
}