.navebar_main {
  display: flex;
  padding: 34px 0px;
  justify-content: space-between;
  align-items: center;
  background: #fefeff;
  box-shadow: 0px 1px 23.9px 0px rgba(0, 0, 0, 0.05);
}
.navlogo{
  margin-left: 122px;
}
.navlogo img {
  height: 38.436px;
  flex-shrink: 0;
}
.navitems {
  display: flex;
  /* padding: 0px 224px; */
  justify-content: center;
  align-items: center;
  gap: 48px;
  flex: 1 0 0;
}
.navitem_buttons {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background-color: white;
  border: none;
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  transition: color 0.5s ease-in-out;
}

.navitem_buttons:hover {
  color: #cb0000;
}

.navitem_login_button {
  display: flex;
  margin-right: 122px;
  width: 74px;
  height: 36px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--Primary-500, #ff5454);
  color: var(--Neutrals-8, #fcfcfd);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  border: none;
  transition: background-color 0.5s ease-in-out;
}
.navitem_login_button:hover {
  background-color: #cb0000;
}

/* mobile navebar css */

/* Updated Navbar.css */

.mobile-navbar {
  display: flex;
  padding: 12px 21px;
  justify-content: space-between;
  align-items: center;
  background: #fefeff;
  box-shadow: 0px 5px 19.9px 0px rgba(0, 0, 0, 0.02);
}

.mobile_navbar_inner_first {
  display: flex;
  align-items: center;
  gap: 12px;
}

.mobile-logo img {
  width: 118.885px;
  height: 24px;
}

.navbar-button {
  display: flex;
  border: none;
  padding: 16px 24px;
  width: 74px;
  height: 36px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: var(--Primary-500, #ff5454);
  color: var(--Neutrals-8, #fcfcfd);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.6px; /* 180% */
}

/* Navbar Menu Animations */
.navbar-menu {
  position: absolute;
  top: -300px; /* Initially hide the menu off-screen */
  left: 0;
  width: 100%;
  background-color: #fff;
  transition: top 0.5s ease-in-out;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.navbar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-menu li {
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.navbar-menu li:last-child {
  border-bottom: none;
}

.navbar-menu.active {
  top: 56px; /* Adjust this value to be below the navbar */
}

/* Responsive styling */
@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
  .navbar-menu {
    display: flex;
    position: static;
    top: 0;
    box-shadow: none;
    flex-direction: row;
  }
  .navbar-menu ul {
    display: flex;
    gap: 20px;
  }
  .navbar-menu li {
    border: none;
    padding: 0;
  }
}


.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  align-items: center;
  text-align: center;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}


/* Responsive styling */
@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
  .navbar-menu {
    display: flex;
  }
}
.mobile-navbar a{
  text-decoration: none; /* This removes the underline */
  color: inherit;
}
.navebar_main a{
  text-decoration: none; /* This removes the underline */
  color: inherit;
}



.navbar-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navbar-menu ul li {
  position: relative;
  cursor: pointer;
  padding: 10px 15px;
}

.sub-dropdown {
  position: absolute;
  top: calc(100% + 5px); /* Adjust the spacing as needed */
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  display: none;
}

.navbar-menu ul li.active .sub-dropdown {
  display: block;
}
.dropdown_content_outer{
  padding: 14px;
  background-color: white;
}
.dropdown-content{
  background-color: white;
  padding: 6px 12px;
  color: var(--Gray-500, #667085);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}











