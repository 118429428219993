.Contactus_page_main {
  margin: 56px 0px;
  background: var(--Gray-50, #f9fafb);
}
.Contactus_page_main_contact {
  display: flex;
  padding: 36px 0px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}
.Contactus_page_main_contact h2 {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 100% */
  letter-spacing: -0.8px;
}
.Contactus_page_main_contact p {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.Contactus_page_main_image {
  margin: 58px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Contactus_page_main_adresses {
  display: flex;
  padding: 80px 96px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  margin: 0px 160px;
}
.Contactus_page_main_adresses_inner_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  background: var(--Gray-100, #f2f4f7);
  padding: 25px;
}

.Contactus_page_main_adresses_inner_content h3 {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.Contactus_page_main_adresses_inner_content p {
  color: var(--Gray-700, #344054);
  text-align: center;

  /* Caption */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.Contactus_page_main_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 137px;
}
.Contactus_page_main_button button {
  display: inline-flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: var(--Primary-500, #ff5454);
  border: none;
  color: var(--Neutrals-8, #fcfcfd);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  transition: background-color 0.5s ease-in-out;
}

.Contactus_page_main_button button:hover {
  background-color: #d54343;
}

.contactus_page_mobile_main {
  display: inline-flex;
  padding: 32px 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  background: var(--Gray-50, #f9fafb);
}

.contactus_page_mobile_inner {
  display: flex;
  padding: 18px 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
.contactus_page_mobile_inner_hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.contactus_page_mobile_inner_hero_solution {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.contactus_page_mobile_inner_hero_solution_text_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.contactus_page_mobile_inner_hero_solution_text_box h2 {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.contactus_page_mobile_inner_hero_solution_text_box p {
  width: 314.412px;
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.contactus_page_mobile_inner_hero_worldmap img {
  width: 352px;
  height: 173.495px;
}

.contactus_page_mobile_inner_location {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.contactus_page_mobile_inner_location_inner {
  display: flex;
  height: 148px;
  width: 288px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--Gray-100, #f2f4f7);
}
.contactus_page_mobile_inner_location_inner_adress{
    width: 288px;
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
flex: 1 0 0;
}
.contactus_page_mobile_inner_location_inner_adress h2{
    color: var(--Gray-500, #667085);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
}
.contactus_page_mobile_inner_location_inner_adress p{
    color: var(--Gray-700, #344054);
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 180% */
}


.contactus_page_mobile_inner_button button{
    display: flex;
    padding: 10.123px 15.184px;
    justify-content: center;
    align-items: center;
    gap: 7.592px;
    border-radius: 3.796px;
background: var(--Primary-500, #FF5454);
color: var(--Neutrals-8, #FCFCFD);
text-align: center;
font-family: Poppins;
font-size: 9.846px;
font-style: normal;
font-weight: 600;
line-height: 15.184px; /* 154.215% */
}