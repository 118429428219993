.pricing_main {
  display: flex;
  margin: 56px 0px;
  padding: 146px 118px;
  align-items: center;
  justify-content: space-between;
  gap: 56px;
}
.pricing_main_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}
.pricing_main_left h3 {
  color: var(--Gray-400, #98a2b3);

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.pricing_main_left h2 {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
}
.pricing_main_left p {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.pricing_main_right {
  display: flex;
  padding: 47.438px 10.709px 47.316px 11.569px;
  justify-content: center;
  align-items: center;
}

.pricing_second {
  display: flex;
  margin-bottom: 56px;
  padding: 82px 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: var(--Gray-50, #f9fafb);
}
.pricing_second_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.pricing_second_inner h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.pricing_second_inner h2 {
  color: #344054;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px; /* 140% */
  letter-spacing: -0.8px;
}
.pricing_second_inner p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.pricing_plans_main {
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.pricing_plans_top {
  width: 1200px;
  display: flex;
  padding: 64px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  border-radius: 10px;
  background: var(--Gray-50, #f9fafb);
}
.pricing_plans_top_p {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: 2.38px;
  text-transform: uppercase;
}

.pricing_plans_top_h2 {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 83.333% */
}
.pricing_plans_top_span {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
.pricing_plans_top_inner {
  display: flex;
  margin-top: 32px;
  align-items: flex-start;
  gap: 35px;
  align-self: stretch;
}
.pricing_plans_top_inner_main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}
.pricing_plans_top_inner_main_head h2 {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
}
.pricing_plans_top_inner_main_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.pricing_plans_top_inner_main_content_inner {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.pricing_plans_top_inner_main_content_inner_text p {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  opacity: 0.88;
}

.pricing_plans_bottom {
  display: flex;
  padding: 64px 40px;
  width: 1200px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  border-radius: 10px;
  background: var(--Primary-100, #fee);
}

.pricing_plans_bottom_p {
  color: var(--Primary-600, #d54343);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: 2.38px;
  text-transform: uppercase;
}
.pricing_plans_bottom_head {
  color: var(--Primary-500, #ff5454);
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 83.333% */
}
.pricing_plans_bottom_span {
  color: var(--Primary-500, #ff5454);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.pricing_plans_bottom_h3 {
  color: var(--Primary-500, #ff5454);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}
.pricing_plans_bottom_inner {
  display: flex;
  margin-top: 32px;
  align-items: flex-start;
  gap: 35px;
  align-self: stretch;
}
.pricing_plans_bottom_inner_main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}
.pricing_plans_bottom_inner_main_head h2 {
  color: var(--Primary-800, #6b1919);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
}
.pricing_plans_bottom_inner_main_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.pricing_plans_bottom_inner_main_content_inner {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.pricing_plans_bottom_inner_main_content_inner_text p {
  color: var(--Primary-700, #aa3232);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
.pricing_mobile_main {
  margin: 32px 0px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.pricing_mobile_hero {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.pricing_mobile_hero_title {
  display: flex;
  width: 393px;
  padding: 0px 22px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

.pricing_mobile_hero_title h3 {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.pricing_mobile_hero_title_text_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.pricing_mobile_hero_title_text_box h2 {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}

.pricing_mobile_hero_title_text_box p {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}
.pricing_mobile_hero_image img {
  width: 230px;
  height: 230px;
}

.pricing_mobile_howitwork {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.pricing_mobile_howitwork_services {
  display: flex;
  width: 393px;
  padding: 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background: var(--Gray-50, #f9fafb);
}
.pricing_mobile_howitwork_services_solutions {
  display: flex;
  padding: 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.pricing_mobile_howitwork_services_solutions h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.pricing_mobile_howitwork_services_solutions_text_box {
  display: flex;
  width: 273px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pricing_mobile_howitwork_services_solutions_text_box h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.pricing_mobile_howitwork_services_solutions_text_box p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}

.pricing_mobile_howitwork_planes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.pricing_mobile_howitwork_planes_top {
  display: flex;
  width: 352px;
  padding: 30px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  border-radius: 6px;
  background: var(--Gray-50, #f9fafb);
}
.pricing_mobile_howitwork_planes_top_h3 {
  color: var(--Gray-500, #667085);
  font-family: Outfit;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 160% */
  letter-spacing: 1.7px;
  text-transform: uppercase;
}
.pricing_mobile_howitwork_planes_top_h2 {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
}

.pricing_mobile_howitwork_planes_top_h2 span {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.pricing_mobile_howitwork_planes_top_line {
  width: 272px;
  height: 1px;
  background: #f2f4f7;
}
.pricing_mobile_howitwork_planes_top_keys {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.pricing_mobile_howitwork_planes_top_keys_inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
}
.pricing_mobile_howitwork_planes_top_keys_inner h2 {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
}
.pricing_mobile_howitwork_planes_top_keys_inner_checks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
}
.pricing_mobile_howitwork_planes_top_keys_inner_checks_frame {
  display: flex;
  gap: 8px;
  align-self: stretch;
}
.pricing_mobile_howitwork_planes_top_keys_inner_checks_frame img {
  width: 24px;
  height: 24px;
}

.pricing_mobile_howitwork_planes_top_keys_inner_checks_frame h2 {
  color: var(--Gray-500, #667085);
  flex: 1 0 0;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 180% */
  opacity: 0.88;
}

.pricing_mobile_howitwork_planes_bottom {
  display: flex;
  width: 352px;
  padding: 30px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  border-radius: 6px;
  background: var(--Primary-100, #fee);
}

.pricing_mobile_howitwork_planes_bottom_h3 {
  color: var(--Primary-600, #d54343);
  font-family: Outfit;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 160% */
  letter-spacing: 1.7px;
  text-transform: uppercase;
}

.pricing_mobile_howitwork_planes_bottom_h2 {
  color: var(--Primary-500, #ff5454);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
}

.pricing_mobile_howitwork_planes_bottom_h2 span {
  color: var(--Primary-500, #ff5454);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.pricing_mobile_howitwork_planes_bottom_h3_second {
  color: var(--Primary-500, #ff5454);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
}
.pricing_mobile_howitwork_planes_bottom_line {
  width: 272px;
  height: 1px;
  background: #f2f4f7;
}

.pricing_mobile_howitwork_planes_bottom_keys {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.pricing_mobile_howitwork_planes_bottom_keys_inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
}
.pricing_mobile_howitwork_planes_bottom_keys_inner h2 {
  color: var(--Primary-800, #6b1919);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
}
.pricing_mobile_howitwork_planes_bottom_keys_inner_checks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
}

.pricing_mobile_howitwork_planes_bottom_keys_inner_checks_frame {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.pricing_mobile_howitwork_planes_top_keys_inner_checks_frame img {
  width: 24px;
  height: 24px;
}

.pricing_mobile_howitwork_planes_bottom_keys_inner_checks_frame h2 {
  flex: 1 0 0;
  color: var(--Primary-700, #aa3232);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 180% */
  opacity: 0.88;
}
