.Comingsoon_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--Gray-100, #f2f4f7);
  padding: 212px 0px;
}

@media screen and (max-width: 768px) {
  .Comingsoon_main {
    padding: 32px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
  }
  .Comingsoon_main img {
    width: 247.338px;
    height: 247.338px;
  }
}
