.aitrip_main {
  display: flex;
  margin: 56px 0px;
  padding: 36px 0px 49.744px 0px;
  flex-direction: column;
  align-items: center;
  gap: 222.744px;
}
.aitrip_main_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.aitrip_main_top h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.aitrip_main_top h2 {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
}
.aitrip_main_top p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.aitrip_feature {
  display: flex;
  margin-bottom: 56px;
  padding: 82px 10px;
  background: var(--Gray-50, #f9fafb);
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.aitrip_feature_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.aitrip_threesec {
  margin: 56px 0px;
  padding: 0px 121px;
  gap: 24px;
}

.aitrip_threesec_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.aitrip_threesec_inner_text {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
}
.aitriop_textleft h2 {
  margin-left: 165px;
}
.aitriop_textright h2 {
  /* margin-right: 165px; */
}

.aitrip_threesec_inner_img {
  display: flex;

  align-items: center;
  justify-content: center;
}
.aitrip_imgright {
  margin-right: 105px;
}
.aitrip_imgleft {
  margin-left: 105px;
}

.aitrip_threesec_inner_textone {
  color: var(--Gray-600, #475467);
  width: 400px;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  margin-right: -50px;
  font-weight: 600;
  line-height: 32px; /* 160% */
  letter-spacing: -0.4px;
  border-radius: 4px;
  
}
.attit_one_one{
  background: rgba(254, 205, 202, 0.5);
}
.attit_one_two{
  background: rgba(254, 223, 137, 0.5);
}
.attit_one_three{
  background: rgba(209, 250, 223, 0.5);
}
.aitrip_threesec_inner_texttwo {
  margin-top: -6px;
  width: 400px;
  
  color: var(--Gray-600, #475467);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  margin-left: -10px;
  font-weight: 600;
  line-height: 32px; /* 160% */
  letter-spacing: -0.4px;
}

.aitripimgthree {
  margin: 45px 0px;
}
.aitrip_howit_works {
  background: var(--Gray-50, #f9fafb);
  margin-bottom: 56px;
  display: flex;
  padding: 82px 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.aitrip_howit_works_inner {
  width: 888px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.aitrip_howit_works_inner h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;

  /* Hairline 2 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}

.aitrip_howit_works_inner h2 {
  color: #344054;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 125% */
  letter-spacing: -0.64px;
}

.aitrip_howit_works_inner p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}
.aitrip_lastthree_sec {
  padding: 0px 120px;
  margin-bottom: 56px;
}
.aitrip_lastthree_sec_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.aitrip_lastthree_sec_inner_box {
  display: flex;
  width: 384px;
height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
}
.aitrip_lastthree_sec_inner_box_one {
  padding: 140px 54.993px;
  border: 1px solid #d0d5dd;
  background: var(--Primary-50, #f9f5ff);
}
.aitrip_lastthree_sec_inner_box_two {
  padding: 136px 23.847px;
  border: 1px solid #d0d5dd;

  background: var(--Pink-50, #fdf2fa);
}
.aitrip_lastthree_sec_inner_box_three {
  padding: 136px 10px;
  border: 1px solid #d0d5dd;

  background: var(--Blue-light-50, #f0f9ff);
}
.aitrip_lastthree_sec_inner_box_inner {
  gap: 16px;
  text-align: center;
}

.aitrip_lastthree_sec_inner_box_inner img {
  padding: 12.727px;
  border-radius: 7.636px;
}

.aitrip_lastthree_sec_inner_box_inner p{
  color: var(--Gray-800, #1D2939);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.28px;
}
.adaimg {
  margin-bottom: 16px;
  background: #e9d7fe;
}
.solanaimg {
  margin-bottom: 24px;
  background: #fcd3f0;
}
.dataimg {
  margin-bottom: 24px;
  background: #d6eefe;
}
.aitrip_ease_into {
  border-radius: 10px;

  margin-bottom: 56px;
  padding: 0px 254.305px;
}
.aitrip_ease_into_inner {
  background: var(--Gray-50, #f9fafb);
  display: flex;
  padding: 99px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.aitrip_ease_into h3 {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}

.aitrip_ease_into button {
  display: flex;
  border: none;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: var(--Primary-100, #fee);
  color: var(--Primary-500, #ff5454);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  transition: background-color 0.5s ease-in-out;
}

.aitrip_ease_into button:hover {
  background-color: #ffcccc;
}
.aitripplaner_mobile_hero {
  display: flex;
  margin: 32px 0px;
  width: 393px;
  padding: 16px 20px;
  flex-direction: column;
  gap: 32px;
}
.aitripplaner_mobile_hero_inner {
  display: flex;
  margin: 16px 20px 32px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.aitripplaner_mobile_hero_inner_solution {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
}

.aitripplaner_mobile_hero_inner_solution h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.aitripplaner_mobile_hero_inner_text_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.aitripplaner_mobile_hero_inner_text_box h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.aitripplaner_mobile_hero_inner_text_box p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}
.aitripplaner_mobile_hero_img img {
  width: 352px;
  height: 150px;
}
.aitripplaner_mobile_best_f {
  display: flex;
  margin-bottom: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.aitripplaner_mobile_best_f_services {
  display: flex;
  padding: 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.aitripplaner_mobile_best_f_services h3 {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
}
.aitripplaner_mobile_best_f_services_text_box {
  display: flex;
  width: 236px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.aitripplaner_mobile_best_f_services_text_box h2 {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}

.aitripplaner_mobile_best_f_services_text_box p {
  color: var(--Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}

.aitripplaner_mobile_best_f_features {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.aitripplaner_mobile_best_f_features_inner {
  display: flex;
  padding: 24px 34px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.aitripplaner_mobile_best_f_features_inner_text_box {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.ambffitbtone {
  border-radius: 3.386px;
  background: rgb(254, 205, 202, 0.5);
}
.ambffitbbone {
  border-radius: 3.386px;
  background: rgb(254, 205, 202, 0.5);
}
.ambffitbttwo {
  background: rgb(254, 223, 137, 0.5);
  border-radius: 5.234px;
}
.ambffitbbtwo {
  background: rgb(254, 223, 137, 0.5);
  border-radius: 5.234px;
}
.ambffitbtthree {
  background: rgba(209, 250, 223, 0.5);
  border-radius: 5.223px;
}
.ambffitbbthree {
  background: rgba(209, 250, 223, 0.5);
  border-radius: 5.223px;
}
.aitripplaner_mobile_best_f_features_inner_text_box_top{
  margin-left: 25px;
}
.aitripplaner_mobile_best_f_features_inner_text_box_bottom{
  margin-right: 25px;
  margin-top: -23px;
}

.aitripplaner_mobile_best_f_features_inner_text_box_top h2 {
  color: var(--Gray-600, #475467);
  width: 300px;
  text-align: center;
  font-family: Poppins;
  font-size: 16.932px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.092px; /* 160% */
  letter-spacing: -0.339px;
}
.aitripplaner_mobile_best_f_features_inner_text_box_bottom h2 {
  color: var(--Gray-600, #475467);
  text-align: center;
  width: 300px;
  font-family: Poppins;
  font-size: 16.932px;
  padding: 1px 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.092px; /* 160% */
  letter-spacing: -0.339px;
}

.aitripplaner_mobile_best_f_features_inner_image img {
  width: 226px;
  height: 226px;
  opacity: 0.7;
 
}
.ambffii{
  transform: rotate(9.772deg);

}
.aitrip_mobile_hiw{
  display: flex;
  margin-bottom: 32px;
flex-direction: column;
align-items: center;
gap: 32px;
}
.aitrip_mobile_hiw_services{
  display: flex;
width: 393px;
padding: 32px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 24px;
background: var(--Gray-50, #F9FAFB);
}
.aitrip_mobile_hiw_services_solution{
  display: flex;
padding: 0px 40px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
}
.aitrip_mobile_hiw_services_solution h3{
  color: var(--Gray-400, #98A2B3);
text-align: center;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 12px; /* 120% */
text-transform: uppercase;
}
.aitrip_mobile_hiw_services_text_box{
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
}
.aitrip_mobile_hiw_services_text_box h2{
  color: var(--Gray-800, #1D2939);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.aitrip_mobile_hiw_services_text_box p{
  color: var(--Gray-400, #98A2B3);
text-align: center;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
letter-spacing: -0.24px;
}
.aitrip_mobile_hiw_features{
  margin: 0px 20.5px;
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 24px;
}
.aitrip_mobile_hiw_features_main{
  display: flex;
padding: 128px 50px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 14px;
align-self: stretch;
}
.amhfmone{
  border-radius: 16px;
border: 0.917px solid #D0D5DD;
background: var(--Primary-50, #F9F5FF);
}
.amhfmone img{
  display: flex;
padding: 12px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 7px;
background: #E9D7FE;
}
.amhfmone h2{
  color: var(--Gray-800, #1D2939);
text-align: center;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 180% */
letter-spacing: -0.2px;
}
.amhfmtwo{
  border-radius: 16px;
border: 0.917px solid #D0D5DD;
background: var(--Pink-50, #FDF2FA);
}
.amhfmtwo img{
  display: flex;
padding: 12px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 7px;
background: #FCD3F0;
}
.amhfmtwo h2{
  color: var(--Gray-800, #1D2939);
text-align: center;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 180% */
letter-spacing: -0.2px;
}
.amhfmthree{
  border-radius: 16px;
border: 0.917px solid #D0D5DD;
background: var(--Blue-light-50, #F0F9FF);
}
.amhfmthree img{
  display: flex;
padding: 11.667px;
justify-content: center;
align-items: center;
gap: 5.833px;
border-radius: 7px;
background: #D6EEFE;
}
.amhfmthree h2{
  color: var(--Gray-800, #1D2939);
text-align: center;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 180% */
letter-spacing: -0.2px;
}